import React, { useState } from "react";
import { useIntl } from "gatsby-plugin-intl";

import "../styles/components/Banner.scss"

export default function Banner() {
  const [hidden, setHidden] = useState(typeof document !== "undefined" ? document.cookie.includes("banner-hidden") : false);
  const intl = useIntl();

  const handleClose = () => {
    setHidden(true);
    document.cookie = "banner-hidden=true"
  };

  if (hidden) return null;

  return (
    <div className="banner">
      <div className="banner__content">
        <button type="button" onClick={handleClose} className="banner__close" />
        <p className="banner__text">
          {intl.formatMessage({ id: "banner-text" })}
        </p>
      </div>
    </div>
  );
}
