import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import "../styles/components/Footer.scss"

import Logo from "../assets/images/icons/logo.svg"
import Banner from "./Banner"

export default function Footer() {
  const intl = useIntl()

  return (
    <>
      <Banner />
      <footer className="footer">
        <div className="footer__content">
          <div className="footer__flex">
            <Link to="/" className="footer__logo-container">
              <img src={Logo} alt="" className="footer__logo" />
            </Link>
            <ul className="footer__list">
              <li className="footer__list-item">
                <Link to="/about" className="footer__link">
                  {intl.formatMessage({ id: "menu-about" })}
                </Link>
              </li>
              {/*   <li className="footer__list-item">
                <Link to="/market" className="footer__link">
                  {intl.formatMessage({ id: "menu-market" })}
                </Link>
              </li> */}
              <li className="footer__list-item">
                <Link to="/platform" className="footer__link">
                  {intl.formatMessage({ id: "menu-platform" })}
                </Link>
              </li>
              {/* <li className="footer__list-item">
                <Link to="/education" className="footer__link">Educación</Link>
              </li> */}
              <li className="footer__list-item">
                <Link to="/faq" className="footer__link">
                  {intl.formatMessage({ id: "menu-faq" })}
                </Link>
              </li>
              <li className="footer__list-item">
                <Link to="/support" className="footer__link">
                  {intl.formatMessage({ id: "menu-support" })}
                </Link>
              </li>
            </ul>
          </div>
          <span className="footer__legend">
            {intl.formatMessage({ id: "copyright" })}
          </span>
        </div>
      </footer>
    </>
  );
}
