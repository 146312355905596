import React, { useState } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import config from "../config";

import "../styles/components/Header.scss"

import Logo from "../assets/images/icons/logo.svg"
import MenuIcon from "../assets/images/icons/icon-menu.svg"

import Language from "./Language"

const LinkToClient = ({ url, styles, text }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" className={styles}>
    {text}
  </a>
)

export default function Header() {
  const intl = useIntl()
  const [menu, setMenu] = useState(false)

  const handleMenu = () => {
    setMenu(!menu)
  }

  return (
    <header className="header">
      <div className="header__content">
        <Link to="/" className="header__logo-container">
          <img src={Logo} alt="" className="header__logo" />
        </Link>
        <nav className="header__nav">
          <div className="header__nav-mobile">
            <Language />
            <button
              type="button"
              className="header__menu-button"
              onClick={handleMenu}
            >
              <img src={MenuIcon} alt="" className="header__menu-button-icon" />
            </button>
          </div>
          <span
            className="header__menu-overlay"
            data-active={menu}
            onClick={handleMenu}
            onKeyDown={handleMenu}
            role="button"
            tabIndex={0}
            aria-label="close"
          />
          <ul
            className="header__list"
            data-active={menu}
            onMouseLeave={menu ? handleMenu : null}
            role="presentation"
            aria-label="onMouseLeave"
          >
            <li className="header__list-item">
              <Link
                to="/about"
                className="header__link"
                activeClassName="header__link--active"
              >
                {" "}
                {intl.formatMessage({ id: "menu-about" })}
              </Link>
            </li>
            <li className="header__list-item">
              {/*      <Link
                to="/market"
                className="header__link"
                activeClassName="header__link--active"
              >
                {" "}
                {intl.formatMessage({ id: "menu-market" })}
              </Link> */}
            </li>
            <li className="header__list-item">
              <Link
                to="/platform"
                className="header__link"
                activeClassName="header__link--active"
              >
                {intl.formatMessage({ id: "menu-platform" })}
              </Link>
            </li>
            {/* <li className="header__list-item">
              <Link to="/education" className="header__link" activeClassName="header__link--active">Educación</Link>
            </li> */}
            <li className="header__list-item">
              <Link
                to="/faq"
                className="header__link"
                activeClassName="header__link--active"
              >
                {intl.formatMessage({ id: "menu-faq" })}
              </Link>
            </li>
            <li className="header__list-item">
              <Link
                to="/support"
                className="header__link"
                activeClassName="header__link--active"
              >
                {intl.formatMessage({ id: "menu-support" })}
              </Link>
            </li>
            <li className="header__list-item">
              <LinkToClient
                url={config.auth_pages.signin}
                styles="header__link header__link--background"
                text={intl.formatMessage({ id: "menu-login" })}
              />
            </li>
            <li className="header__list-item">
              <LinkToClient
                url={config.auth_pages.signup}
                styles="header__link"
                text={intl.formatMessage({ id: "menu-register" })}
              />
            </li>
            <li className="header__list-item">
              <Language />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}
