import React, { useEffect } from "react";

import "../styles/General.scss";

import Header from "./Header.jsx";
import Footer from "./Footer.jsx";

function Layout({ children }) {
  useEffect(() => {
    const script = document.createElement("script");
    const scriptTwo = document.createElement("script")

    script.textContent = `
      function initFreshChat() {
        window.fcWidget.init({
          token: "001b316a-eea8-4fdc-8e84-9d893ab4cf34",
          host: "https://wchat.freshchat.com"
        });
      }
      function initialize(i,t) {
        var e;
        i.getElementById(t)
          ? initFreshChat()
          : ((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))
      }
      function initiateCall(){
        initialize(document,"Freshdesk Messaging-js-sdk")
      }
      window.addEventListener ? window.addEventListener("load",initiateCall,!1) : window.attachEvent("load",initiateCall,!1);
    `;
    scriptTwo.type = "text/javascript"
    scriptTwo.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    scriptTwo.async = true;

    document.body.appendChild(script);
    document.head.appendChild(scriptTwo);
    
    return () => {
      document.body.removeChild(script);
      document.head.removeChild(scriptTwo);
    };
  }, []);

  return (
    <>
      <Header />
      {children}
      <Footer />
      <button
        id="scroll-btn"
        className="sidebar-btns"
        title="Scroll to top"
        aria-label="go top"
      ></button>
    </>
  );
}

export default Layout;
