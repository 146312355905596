import React, { useState } from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

import "../styles/components/Language.scss"

import LanguageIcon from "../assets/images/icons/icon-language.svg"

export default function Language() {
  const [menu, setMenu] = useState(false)

  const handleMenu = () => {
    setMenu(!menu)
  }

  const handleChangeLanguage = lang => () => {
    changeLocale(lang === "en" ? "es" : "en")
  }

  return (
    <IntlContextConsumer>
      {({ language }) => (
        <div className="language">
          <button className="language__content" onClick={handleMenu}>
            <img src={LanguageIcon} alt="" className="language__icon" />
            {language}
          </button>
          <div
            className="language__menu"
            data-active={menu}
            onMouseLeave={handleMenu}
            role="presentation"
            aria-label="onMouseLeave"
          >
            <button
              onClick={handleChangeLanguage(language)}
              className="language__menu-button"
              type="button"
              disabled={language === "es"}
            >
              ES
            </button>
            <button
              onClick={handleChangeLanguage(language)}
              className="language__menu-button"
              type="button"
              disabled={language === "en"}
            >
              EN
            </button>
          </div>
        </div>
      )}
    </IntlContextConsumer>
  )
}
